import React, { useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { createCategory } from '../api';

const ModelDetails = ({ modelName, handleModelNameChange, category, categories, handleCategoryChange, createCategory }) => {
    const [isAddingCategory, setIsAddingCategory] = useState(false);
    const [newCategory, setNewCategory] = useState('');

    const handleAddCategoryClick = () => {
        setIsAddingCategory(true);
    };

    const handleNewCategoryChange = (e) => {
        setNewCategory(e.target.value);
    };

    const handleSaveNewCategory = () => {
        if (newCategory.trim()) {
            createCategory(newCategory);
            setIsAddingCategory(false);
            setNewCategory('');
        }
    };

    return (
        <Row className="mb-3">
            <Col>
                <Form.Group>
                    <Form.Label className="form-label">Nom du modèle</Form.Label>
                    <Form.Control
                        type="text"
                        value={modelName}
                        onChange={handleModelNameChange}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label className="form-label">Catégorie</Form.Label>
                    <div className="d-flex">
                        {isAddingCategory ? (
                            <>
                                <Form.Control
                                    type="text"
                                    placeholder="Nouvelle catégorie"
                                    value={newCategory}
                                    onChange={handleNewCategoryChange}
                                />
                                <Button
                                    variant="success"
                                    className="ms-2"
                                    onClick={handleSaveNewCategory}
                                >
                                    Enregistrer
                                </Button>
                            </>
                        ) : (
                            <>
                                <Form.Select
                                    value={category}
                                    onChange={handleCategoryChange}
                                >
                                    <option value="">Sélectionner une catégorie</option>
                                    {categories.map((cat, index) => (
                                        <option key={index} value={cat}>{cat}</option>
                                    ))}
                                </Form.Select>
                                <Button
                                    variant="primary"
                                    className="ms-2"
                                    onClick={handleAddCategoryClick}
                                >
                                    +
                                </Button>
                            </>
                        )}
                    </div>
                </Form.Group>
            </Col>
        </Row>
    );
};

export default ModelDetails;