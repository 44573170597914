import axios from 'axios';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://licence-api.ogmentis.fr',
});

// Add a request interceptor to attach the token
API.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Add a response interceptor to handle errors globally
API.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response && error.response.status === 401) {
    window.location = '/login';
  }
  return Promise.reject(error);
});

// User API calls
export const fetchUsers = () => API.get(`/api/User`);
export const fetchUser = (id) => API.get(`/api/User/${id}`);
export const fetchCurrentUser = () => API.get('/api/User/current');

// Category API calls
export const fetchCategory = () => API.get('/api/Categorie');
export const createCategory = (category) => API.post('/api/Categorie', category);

// GroupModel API calls
export const createGroupModel = (groupModel) => API.post('/api/GroupeModel', groupModel);

// Nuancier API calls
export const createNuancier = (color) => API.post('/api/Nuancier', color);
export const fetchColors = () => API.get('/api/Nuancier');

// Model API calls
export const createItem = (model) => API.post('/api/Model', model);
export const fetchModels = () => API.get('/api/Model');
export const fetchGroupModels = () => API.get('/api/GroupeModel');

// Option API calls
export const createOption = (option) => API.post('/api/Option', option);
export const updateOption = (option) => API.put('/api/Option', option);

// Proposition API calls
export const fetchProposition = (id) => API.get(`/api/Propositions/${id}`);

export const generateSecureLink = (propositionId, filename) =>
    API.post(`/api/Video/generate-link/${propositionId}/${filename}`);

// File upload API call
export const uploadFile = async (file, userName) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`http://localhost:5166/api/Uploader/uploadImage?userName=${userName}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}` // Add this line to include the token
      }
    });
    return response.data.filePath;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};

export const fetchCustomers = () => API.get('/api/Customers/forCurrentUser');
export const fetchCustomer = (id) => API.get(`/api/Customers/${id}`);
export const createCustomer = (customerData) => API.post('/api/Customers', customerData);
export const deleteCustomer = (id) => API.delete(`/api/Customers/${id}`);
export const fetchCustomerDetails = (id) => API.get(`/api/Customers/${id}`);
export const updateCustomerDetails = (id, customerData) => API.put(`/api/Customers/${id}`, customerData);

export const editEntreprise = (id, data) => API.put(`/api/Entreprise/${id}`, data)
export const fetchEntreprise = (id) => API.get(`/api/Entreprise/${id}`);
// Nouvelle méthode pour récupérer les clients d'un utilisateur spécifique
export const fetchCustomersForUser = (userId) => {
  return API.get(`/api/Customers/forUser/${userId}`);
};



