import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Row, Col, Table, ListGroup, InputGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {createOption, fetchModels, fetchColors, fetchCurrentUser} from '../api';
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from '../navbar.js';

const GroupForm = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [groupName, setGroupName] = useState('');
    const [optionType, setOptionType] = useState('');
    const [toggleOrList, setToggleOrList] = useState('');
    const [models, setModels] = useState([]);
    const [colors, setColors] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [selectedModelForToggle, setSelectedModelForToggle] = useState('');
    const [items, setItems] = useState([]);
    const [leftInput, setLeftInput] = useState('');
    const [rightInput, setRightInput] = useState('');
    const [listItems, setListItems] = useState([]);
    const [newListItem, setNewListItem] = useState('');
    const [sousOptions, setSousOptions] = useState({});
    const [tableSnapshots, setTableSnapshots] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();

    const getGroupIdFromQuery = () => {
        const params = new URLSearchParams(location.search);
        return params.get('groupId');
    };

    useEffect(() => {
        const getUser = async () => {
            try {
                const response = await fetchCurrentUser();
                setUser(response.data);
                console.log(response.data);
            } catch (err) {
                console.error('Error fetching user:', err);
                setError('Error fetching user');
            } finally {
                setLoading(false);
            }
        };

        getUser();
    }, []);

    const logout = () => {
        localStorage.removeItem('token');
        window.location.reload();
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const modelsResponse = await fetchModels();
                const colorsResponse = await fetchColors();

                const groupId = getGroupIdFromQuery();
                if (groupId) {
                    const parsedGroupId = parseInt(groupId, 10);
                    if (!isNaN(parsedGroupId)) {
                        const filteredModels = modelsResponse.data.$values.filter(model => model.idGroupeModel === parsedGroupId);
                        // Traitez les modèles pour inclure ceux sans sous-divisions
                        const modelsWithSubDivisions = filteredModels.map(model => ({
                            id: model.id,
                            specification: model.specification,
                            subOptions: sousOptions[model.id] || [{ sousNom: '', sousIcone: '' }]
                        }));
                        setModels(modelsWithSubDivisions);
                    } else {
                        console.error('Invalid groupId:', groupId);
                    }
                } else {
                    console.error('groupId parameter is missing in the URL');
                }

                if (colorsResponse.data && Array.isArray(colorsResponse.data.$values)) {
                    const colorNames = colorsResponse.data.$values.map(color => color.nom);
                    setColors(colorNames);
                } else {
                    console.error('API response for colors does not contain an array:', colorsResponse.data);
                }

                const createdOptions = JSON.parse(localStorage.getItem('createdOptions'));
                if (createdOptions) {
                    const sousOptionsMap = {};
                    createdOptions.forEach(item => {
                        if (!sousOptionsMap[item.IdModel]) {
                            sousOptionsMap[item.IdModel] = [];
                        }
                        sousOptionsMap[item.IdModel].push({
                            sousNom: item.SousNom,
                            sousIcone: item.SousIcone
                        });
                    });

                    setSousOptions(sousOptionsMap);
                    localStorage.removeItem('createdOptions');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [location]);


    const handleCheckboxChange = (modelId, colorName, checked) => {
        setSelectedOptions(prevOptions => {
            const newOptions = { ...prevOptions };

            if (!newOptions[modelId]) {
                newOptions[modelId] = [];
            }

            if (checked) {
                newOptions[modelId].push(colorName);
            } else {
                newOptions[modelId] = newOptions[modelId].filter(option => option !== colorName);
            }

            return newOptions;
        });
    };

    const handleRemoveTableSnapshot = (index) => {
        setTableSnapshots(tableSnapshots.filter((_, i) => i !== index));
    };

    const handleAddListItem = () => {
        if (newListItem.trim() && listItems.length < 5) {
            setListItems([...listItems, newListItem]);
            setNewListItem('');
        }
    };

    const handleRemoveListItem = (index) => {
        setListItems(listItems.filter((_, i) => i !== index));
    };

    const handleAddItem = () => {
        const newItem = {
            optionName: groupName,
            optionType,
            toggleOrList,
            leftInput,
            rightInput,
            listItems: [...listItems],
            selectedOptions: { ...selectedOptions },
            selectedModel: { ...selectedModelForToggle }
        };

        setItems([...items, newItem]);

        setGroupName('');
        setOptionType('');
        setToggleOrList('');
        setLeftInput('');
        setRightInput('');
        setListItems([]);
        setSelectedOptions({});
        setSelectedModelForToggle({ idModel: '', sousNom: '' });
    };

    const handleRemoveItem = (index) => {
        setItems(items.filter((_, i) => i !== index));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!groupName && items.length === 0 && tableSnapshots.length === 0) {
            alert('Le nom du groupe est requis');
            return;
        }

        try {
            const createOptionPayloads = [];

            // Process each item in the list
            items.forEach(item => {
                const { optionName, optionType, toggleOrList, leftInput, rightInput, listItems, selectedOptions, selectedModel } = item;

                // Handling Coloris type options
                if (optionType === 'Coloris') {
                    for (const modelId in selectedOptions) {
                        const selectedColors = selectedOptions[modelId];
                        const [actualModelId, sousNom] = modelId.split('-');

                        selectedColors.forEach(colorName => {
                            createOptionPayloads.push({
                                idModel: actualModelId,
                                typeOption: optionType,
                                nomGroupe: optionName,
                                nom: colorName,
                                cheminIconeGroupe: '',
                                cheminIcone: '',
                                chemin3d: '',
                                colorTag: '',
                                sousNom: sousNom,
                                sousIcone: sousOptions[actualModelId]?.find(opt => opt.sousNom === sousNom)?.sousIcone || '',
                                toggle: false,
                                liste: false,
                                idModelNavigation: null,
                            });
                        });
                    }
                }

                // Handling Texte type options
                if (optionType === 'Texte') {
                    const [idModel, sousNom] = selectedModel?.idModel ? [selectedModel.idModel, selectedModel.sousNom] : [null, null];

                    if (idModel && sousNom) {
                        const sousOptionSelected = sousOptions[idModel]?.find(opt => opt.sousNom === sousNom);

                        if (sousOptionSelected) {
                            if (toggleOrList === 'Bascule') {
                                createOptionPayloads.push({
                                    idModel,
                                    typeOption: optionType,
                                    nomGroupe: optionName,
                                    nom: rightInput,
                                    cheminIconeGroupe: '',
                                    cheminIcone: '',
                                    chemin3d: '',
                                    colorTag: '',
                                    sousNom: sousOptionSelected.sousNom,
                                    sousIcone: sousOptionSelected.sousIcone,
                                    toggle: true,
                                    liste: false,
                                    idModelNavigation: null,
                                });
                                createOptionPayloads.push({
                                    idModel,
                                    typeOption: optionType,
                                    nomGroupe: optionName,
                                    nom: leftInput,
                                    cheminIconeGroupe: '',
                                    cheminIcone: '',
                                    chemin3d: '',
                                    colorTag: '',
                                    sousNom: sousOptionSelected.sousNom,
                                    sousIcone: sousOptionSelected.sousIcone,
                                    toggle: true,
                                    liste: false,
                                    idModelNavigation: null,
                                });
                            } else if (toggleOrList === 'Liste') {
                                listItems.forEach(listItem => {
                                    createOptionPayloads.push({
                                        idModel,
                                        typeOption: optionType,
                                        nomGroupe: optionName,
                                        nom: listItem,
                                        cheminIconeGroupe: '',
                                        cheminIcone: '',
                                        chemin3d: '',
                                        colorTag: '',
                                        sousNom: sousOptionSelected.sousNom,
                                        sousIcone: sousOptionSelected.sousIcone,
                                        toggle: false,
                                        liste: true,
                                        idModelNavigation: null,
                                    });
                                });
                            }
                        } else {
                            console.warn(`Aucune sous-option trouvée pour le modèle "${idModel}" et le sous-nom "${sousNom}".`);
                        }
                    } else {
                        console.warn('Modèle ou sous-nom non défini.');
                    }
                }
            });

            // Adding table snapshots to the payload
            tableSnapshots.forEach(snapshot => {
                snapshot.forEach(item => {
                    createOptionPayloads.push(item);
                });
            });

            console.log('Final Payloads:', createOptionPayloads);

            // Sending all payloads to the API
            await Promise.all(createOptionPayloads.map(payload => createOption(payload)));

            // Resetting states
            setGroupName('');
            setOptionType('');
            setToggleOrList('');
            setLeftInput('');
            setRightInput('');
            setListItems([]);
            setSelectedOptions({});
            setItems([]);
            setSelectedModelForToggle('');
            setTableSnapshots([]);

            navigate('/dashboard');
        } catch (error) {
            console.error('Erreur lors de la soumission du formulaire:', error.response?.data || error.message);
            alert(`Erreur: ${error.response?.data || error.message}`);
        }
    };

    const handleSeeNuancier = () => {
        const groupId = getGroupIdFromQuery();
        navigate(`/nuancier?groupId=${groupId}`);
    };

    const formContainerStyle = {
        width: '70%',          // Augmenter la largeur du formulaire
        maxWidth: '900px',    // Largeur maximale
        margin: '0 auto',     // Centrer horizontalement
        padding: '20px',      // Ajouter de l'espace intérieur
        borderRadius: '8px',  // Coins arrondis (optionnelle)
    };

    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Hauteur de la fenêtre pour centrer verticalement
    };

    return (
        <Layout user={user} logout={logout}>
            <Container style={containerStyle}>
                <Form style={formContainerStyle} onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="groupName">Nom de groupe :</Form.Label>
                        <Form.Control
                            type="text"
                            id="groupName"
                            value={groupName}
                            onChange={(e) => setGroupName(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="optionType">Type d'option :</Form.Label>
                        <Form.Select
                            id="optionType"
                            value={optionType}
                            onChange={(e) => setOptionType(e.target.value)}
                        >
                            <option value="">Sélectionner un type</option>
                            <option value="Texte">Texte</option>
                            <option value="Coloris">Coloris</option>
                        </Form.Select>
                    </Form.Group>

                    {optionType && (
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="toggleOrList">Choisir :</Form.Label>
                            <Form.Select
                                id="toggleOrList"
                                value={toggleOrList}
                                onChange={(e) => setToggleOrList(e.target.value)}
                            >
                                <option value="">Sélectionner une option</option>
                                <option value="Bascule">Bascule</option>
                                <option value="Liste">Liste</option>
                            </Form.Select>
                        </Form.Group>
                    )}

                    {optionType === 'Texte' && toggleOrList === 'Bascule' && (
                        <div>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="modelSelect">Sélectionner un modèle :</Form.Label>
                                <Form.Select
                                    id="modelSelect"
                                    value={`${selectedModelForToggle.idModel}-${selectedModelForToggle.sousNom}`}
                                    onChange={(e) => {
                                        const [idModel, sousNom] = e.target.value.split('-');
                                        setSelectedModelForToggle({idModel, sousNom});
                                        if (sousOptions[idModel]) {
                                            //console.log(Options pour le modèle ${idModel} et sous-nom ${sousNom}:, sousOptions[idModel].filter(option => option.sousNom === sousNom));
                                        } else {
                                            console.warn('Aucune donnée trouvée pour ce modèle et sous-nom.');
                                        }
                                    }}
                                >
                                    <option value="">Sélectionner un modèle</option>
                                    {models.map(model => (
                                        sousOptions[model.id]?.map(option => (
                                            <option
                                                key={`${model.id}-${option.sousNom}} value={${model.id}-${option.sousNom}`}>
                                                {model.specification} - {option.sousNom}
                                                {option.sousIcone && <img src={option.sousIcone} alt={option.sousNom}
                                                                          style={{width: '20px', marginLeft: '5px'}}/>}
                                            </option>
                                        ))
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <Row className="mb-3">
                                <Col>
                                    <Form.Control
                                        type="text"
                                        id="toggleInputLeft"
                                        value={leftInput}
                                        onChange={(e) => setLeftInput(e.target.value)}
                                        placeholder="Gauche"
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        id="toggleInputRight"
                                        value={rightInput}
                                        onChange={(e) => setRightInput(e.target.value)}
                                        placeholder="Droite"
                                    />
                                </Col>
                            </Row>
                        </div>
                    )}

                    {optionType === 'Texte' && toggleOrList === 'Liste' && (
                        <div>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="modelSelect">Sélectionner un modèle :</Form.Label>
                                <Form.Select
                                    id="modelSelect"
                                    value={`${selectedModelForToggle.idModel}-${selectedModelForToggle.sousNom}`}
                                    onChange={(e) => {
                                        const [idModel, sousNom] = e.target.value.split('-');
                                        setSelectedModelForToggle({idModel, sousNom});
                                        if (sousOptions[idModel]) {
                                            //console.log(Options pour le modèle ${idModel} et sous-nom ${sousNom}:, sousOptions[idModel].filter(option => option.sousNom === sousNom));
                                        } else {
                                            console.warn('Aucune donnée trouvée pour ce modèle et sous-nom.');
                                        }
                                    }}
                                >
                                    <option value="">Sélectionner un modèle</option>
                                    {models.map(model => (
                                        sousOptions[model.id]?.map(option => (
                                            <option key={`${model.id}-${option.sousNom}`}
                                                    value={`${model.id}-${option.sousNom}`}>
                                                {model.specification} - {option.sousNom}
                                                {option.sousIcone && <img src={option.sousIcone} alt={option.sousNom}
                                                                          style={{width: '20px', marginLeft: '5px'}}/>}
                                            </option>
                                        ))
                                    ))}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Liste :</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="text"
                                        value={newListItem}
                                        onChange={(e) => setNewListItem(e.target.value)}
                                        placeholder="Ajouter un élément"
                                    />
                                    <Button variant="outline-secondary" onClick={handleAddListItem}>+</Button>
                                </InputGroup>

                                <ListGroup>
                                    {listItems.map((item, index) => (
                                        <ListGroup.Item key={index}>
                                            {item}
                                            <Button variant="outline-danger" size="sm"
                                                    onClick={() => handleRemoveListItem(index)}>X</Button>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </Form.Group>
                        </div>
                    )}

                    {optionType === 'Coloris' && (
                        <div className="color-options">
                            <Table striped bordered hover responsive>
                                <thead>
                                <tr>
                                    <th>Modèle</th>
                                    {colors.map((color, index) => (
                                        <th key={index}>{color}</th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {models.map((model) => (
                                    sousOptions[model.id]?.map((option) => (
                                        <tr key={`${model.id}-${option.sousNom}`}>
                                            <td>{model.specification} - {option.sousNom}</td>
                                            {colors.map((color, colIndex) => (
                                                <td key={colIndex}>
                                                    <Form.Check
                                                        type="checkbox"
                                                        onChange={(e) => handleCheckboxChange(`${model.id}-${option.sousNom}`, color, e.target.checked)}
                                                        checked={selectedOptions[`${model.id}-${option.sousNom}`]?.includes(color)}
                                                    />
                                                </td>
                                            ))}
                                        </tr>
                                    ))
                                ))}
                                </tbody>
                            </Table>
                            <Button variant="primary" onClick={handleSeeNuancier}>Voir le nuancier</Button>
                        </div>
                    )}

                    <div className="form-actions">
                        <Button
                            variant="secondary"
                            type="button"
                            className="me-2"
                            style={{backgroundColor: '#ff2b2b', borderColor: '#ff2b2b'}}
                        >
                            Annuler
                        </Button>
                        <Button
                            variant="primary"
                            type="submit"
                            className="me-2"
                            style={{backgroundColor: '#2fe5a7', borderColor: '#2fe5a7'}}
                        >
                            Valider
                        </Button>
                        {optionType && (
                            <Button
                                variant="success"
                                type="button"
                                onClick={handleAddItem}
                            >
                                Ajouter
                            </Button>
                        )}
                    </div>
                </Form>

                <div className="items-list mt-4">
                    {items.map((item, index) => (
                        <div key={index} className="item mb-3 p-3 border rounded">
                            <Button variant="outline-danger" size="sm" className="mb-2"
                                    onClick={() => handleRemoveItem(index)}>X</Button>
                            <div className="item-row">
                                <p><strong>Nom de l'option:</strong> {item.optionName}</p>
                                {item.optionType === 'Texte' && (
                                    <div>
                                        <p><strong>Choix :</strong> {item.toggleOrList}</p>
                                    {item.toggleOrList === 'Bascule' && (
                                        <div>
                                            <p><strong>Gauche :</strong> {item.leftInput}</p>
                                            <p><strong>Droite :</strong> {item.rightInput}</p>
                                        </div>
                                    )}
                                    {item.toggleOrList === 'Liste' && (
                                        <ul>
                                            {item.listItems.map((listItem, idx) => (
                                                <li key={idx}>{listItem}</li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            )}
                            {item.optionType === 'Coloris' && (
                                <div className="color-options">
                                    <div className="table-snapshots mt-4">
                                        {tableSnapshots.map((snapshot, index) => (
                                            <div key={index} className="snapshot mb-3 p-3 border rounded">
                                                <Button variant="outline-danger" size="sm" className="mb-2"
                                                        onClick={() => handleRemoveTableSnapshot(index)}>X</Button>
                                                <Table striped bordered hover responsive>
                                                    <thead>
                                                    <tr>
                                                        <th>Modèle</th>
                                                        <th>Nuance</th>
                                                        <th>Sous-nom</th>
                                                        <th>Sous-icone</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {snapshot.map((item, idx) => (
                                                        <tr key={idx}>
                                                            <td>{models.find(model => model.id === parseInt(item.idModel))?.specification}</td>
                                                            <td>{item.nom}</td>
                                                            <td>{item.sousNom}</td>
                                                            <td>{item.sousIcone &&
                                                                <img src={item.sousIcone} alt={item.sousNom}
                                                                     style={{width: '20px'}}/>}</td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </Container>
        </Layout>
    );
};

export default GroupForm;