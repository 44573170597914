import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';



const LoginPage = () => {
    const [mail, setMail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { login, isAuthenticated, authError } = useAuth();

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/dashboard');
        }
    }, [isAuthenticated, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await login(mail, password);
        } catch (error) {
            console.error('Une erreur s\'est produite lors de la connexion :', error);
        }
    };
    

    return (
        <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <Row>
                <Col>
                    <div className="p-5" style={{ backgroundColor: '#6b83e3', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', maxWidth: '400px', margin: '0 auto' }}>
                        <h2 className="text-center text-white mb-4">Page de Connexion</h2>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label className="text-white">Email:</Form.Label>
                                <Form.Control
                                    type="email"
                                    value={mail}
                                    onChange={(e) => setMail(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="password">
                                <Form.Label className="text-white">Mot de passe:</Form.Label>
                                <Form.Control
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Button
                                type="submit"
                                style={{ backgroundColor: '#2ce3a4', borderColor: '#2ce3a4' }}
                                className="w-100"
                            >
                                Se connecter
                            </Button>
                        </Form>
                        {authError && <Alert variant="danger" className="mt-3 text-center">{authError}</Alert>}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default LoginPage;