import axios from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL || 'https://licence-api.ogmentis.fr';

export const login = async (mail, password) => {
  try {
    const response = await axios.post(`${API_URL}/api/Auth/backoffice/login`, { mail, password });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to login');
  }
};