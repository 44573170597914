import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BiGridAlt, BiUserCircle, BiBook, BiCog, BiLogOut } from 'react-icons/bi';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Circles } from 'react-loader-spinner';

const Layout = ({ user, logout, children }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (user) {
            setLoading(false); // Une fois que l'utilisateur est chargé, on arrête le chargement
        }
    }, [user]);

    const userName = user ? `${user.prenom} ${user.nom}` : 'Utilisateur';

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Circles
                    height="80"
                    width="80"
                    color="#6576ec"
                    ariaLabel="circles-loading"
                    visible={true}
                />
            </div>
        );
    }

    return (
        <Container fluid className="p-0">
            <Row className="no-gutters">
                <Col md={2} className="p-0">
                    <Navbar
                        style={{
                            backgroundColor: '#6576ec',
                            minHeight: '100vh',
                            paddingTop: '5rem',
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '16.66%',
                            zIndex: 1000,
                        }}
                        className="flex-column justify-content-between"
                    >
                        <Nav className="flex-column">
                            <Nav.Link as={Link} to="/dashboard" style={{ color: 'white', fontSize: '1.3rem' }}> 
                                <BiGridAlt className="me-2" />
                                Tableau de Bord
                            </Nav.Link>

                            <Nav.Link as={Link} to="/customers" style={{ color: 'white', fontSize: '1.3rem' }}> 
                                <BiUserCircle className="me-2" />
                                Clients
                            </Nav.Link>

                            <Nav.Link as={Link} to="/catalogue" style={{ color: 'white', fontSize: '1.3rem' }}> 
                                <BiBook className="me-2" />
                                Catalogue
                            </Nav.Link>

                            <Nav.Link as={Link} to="/users" style={{ color: 'white', fontSize: '1.3rem' }}> 
                                <BiUserCircle className="me-2" />
                                Utilisateurs
                            </Nav.Link>

                            <Nav.Link as={Link} to="..." style={{ color: 'white', fontSize: '1.3rem' }}> 
                                <BiCog className="me-2" />
                                Paramètres
                            </Nav.Link>
                        </Nav>

                        {/* Placement du nom d'utilisateur légèrement plus haut */}
                        <Nav className="flex-column">
                            <Nav.Link href="#" style={{ color: 'white', fontSize: '1.4rem', marginBottom: '2rem' }} onClick={logout}>
                                <BiLogOut className="me-2" />
                                {userName}
                            </Nav.Link>
                        </Nav>
                    </Navbar>
                </Col>

                <Col md={{ span: 10, offset: 2 }} className="content" style={{ marginLeft: '16.66%' }}>
                    {children}
                </Col>
            </Row>
        </Container>
    );
};

export default Layout;
