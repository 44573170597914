import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { BiEnvelope, BiCalendarWeek } from 'react-icons/bi';
import 'bootstrap/dist/css/bootstrap.min.css';
import { fetchCustomers, fetchCurrentUser } from '../api';
import Layout from '../navbar.js';

const CustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const getCustomers = async () => {
      try {
        const response = await fetchCustomers();
        if (response.data && Array.isArray(response.data.$values)) {
          setCustomers(response.data.$values);
        } else {
          setError('Unexpected response format');
        }
      } catch (err) {
        setError('Error fetching customers');
      } finally {
        setLoading(false);
      }
    };

    const getUser = async () => {
      try {
        const response = await fetchCurrentUser();
        setUser(response.data);
      } catch (err) {
        setError('Error fetching user');
      }
    };

    getCustomers();
    getUser();
  }, []);

  const logout = () => {
    localStorage.removeItem('token');
    window.location.reload();
  };

  if (error) {
    return <div>{error}</div>;
  }

  const handleCardClick = (id) => {
    navigate(`/customers/${id}`);
  };

  return (
    <Layout user={user} logout={logout}>
      {/* Barre blanche verticale pour créer un espace après la navbar */}
      <div style={{ backgroundColor: 'white', width: '20px', height: '100vh', position: 'fixed', top: 0, left: '16.66%', zIndex: 999 }}></div>

      <div style={{ marginLeft: '50px', paddingRight: '50px' }}> {/* Décalage du contenu principal pour prendre en compte la barre blanche */}
        <Row className="align-items-center mb-4">
        <Col className="d-flex justify-content-start" style={{ paddingLeft: '6rem' }}>
              <h1 style={{ color: '#6b83e3' }}>Liste des Clients</h1>
          </Col>
          <Col xs="auto" className="ms-auto d-flex justify-content-end align-items-center">
              <img
                  src="https://ogmentis.fr/images/Logo_low.png"
                  alt="Ogmentis Logo"
                  style={{
                      height: 'calc(2.7em + 2.2rem)',
                      width: 'auto'
                  }}
              />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={3}>
            <div className="d-flex align-items-center justify-content-center">
              <span style={{ color: '#6b83e3' }}>Nom et Prénom ▼</span>
            </div>
          </Col>
          <Col md={4}>
            <div className="d-flex align-items-center justify-content-center">
              <span style={{ color: '#6b83e3' }}>Email ▼</span>
            </div>
          </Col>
          <Col md={3}>
            <div className="d-flex align-items-center justify-content-center">
              <span style={{ color: '#6b83e3' }}>Date de création ▼</span>
            </div>
          </Col>
          <Col md={2}>
            <div className="d-flex align-items-center justify-content-center">
              <span style={{ color: '#6b83e3' }}>Nombre de Propositions ▼</span>
            </div>
          </Col>
        </Row>

        {customers.map((customer) => (
          <Row key={customer.customerId} className="my-3">
            <Col>
              <Card
                className="border-0 shadow rounded mb-3 customer-card"
                style={{ backgroundColor: '#fbf3f3' }}
                onClick={() => handleCardClick(customer.customerId)}
              >
                <Card.Body>
                  <Row className="align-items-center">
                    <Col md={3} className="text-center">
                      <div style={{ color: '#6b83e3' }}>{`${customer.nom} ${customer.prenom}`}</div>
                    </Col>
                    <Col md={4} className="text-center">
                      <div style={{ color: '#6b83e3' }}>
                        <BiEnvelope style={{ color: '#6b83e3' }} /> {customer.mail}
                      </div>
                    </Col>
                    <Col md={3} className="text-center">
                      <div style={{ color: '#6b83e3' }}>
                        <BiCalendarWeek style={{ color: '#6b83e3' }} /> {customer.createdAt}
                      </div>
                    </Col>
                    <Col md={2} className="text-center">
                      <div style={{ color: '#6b83e3' }}>{customer.propositionCount || 0}</div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ))}
      </div>
    </Layout>
  );
};

export default CustomerList;
