import React from 'react';
import { Button } from 'react-bootstrap';

const ItemList = ({ items, handleRemoveItem }) => {
    return (
        <div className="mt-4">
            <h2>Liste des Items</h2>
            {items.length === 0 ? (
                <p>Aucun item ajouté.</p>
            ) : (
                <ul>
                    {items.map((item, index) => (
                        <li key={index} style={{ marginBottom: '20px' }}>
                            <h4>Item {index + 1}</h4>
                            <p>Specification: {item.specification}</p>
                            <p>Prix catalogue: {item.catalogPrice}€</p>
                            {item.icons.map((icon, iconIndex) => (
                                <img
                                    key={iconIndex}
                                    src={icon} // Utilisez directement l'URL ici
                                    alt={`Icon ${iconIndex + 1}`}
                                    style={{ maxWidth: '100px', marginRight: '10px' }}
                                />
                            ))}
                            {item.model3D && (
                                <div>
                                    <h5>Modèle 3D:</h5>
                                    <a href={item.model3D} target="_blank" rel="noopener noreferrer">
                                        Voir le modèle 3D
                                    </a>
                                </div>
                            )}
                            {item.pdf && (
                                <div>
                                    <h5>PDF:</h5>
                                    <a href={item.pdf} target="_blank" rel="noopener noreferrer">
                                        Voir le PDF
                                    </a>
                                </div>
                            )}
                            {item.ficheDeCote && (
                                <div>
                                    <h5>Fiche de Cote:</h5>
                                    <a href={item.ficheDeCote} target="_blank" rel="noopener noreferrer">
                                        Voir la fiche de cote
                                    </a>
                                </div>
                            )}
                            <Button onClick={() => handleRemoveItem(index)}>Supprimer</Button>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default ItemList;