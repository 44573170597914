import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom'; // Importer useNavigate
import 'bootstrap/dist/css/bootstrap.min.css';
import { fetchModels, createNuancier } from '../api'; // Assurez-vous que le chemin est correct pour votre projet

const SubDiv = () => {
    const [rows, setRows] = useState([{ Nom: '', Icone: '', Model3d: '' }]);
    const [showModal, setShowModal] = useState(false);
    const [currentRowIndex, setCurrentRowIndex] = useState(null);
    const [tempSizes, setTempSizes] = useState([]);
    const [models, setModels] = useState([]);
    const [createdModels, setCreatedModels] = useState([]);
    const location = useLocation();
    const navigate = useNavigate(); // Initialiser useNavigate

    // Fonction pour récupérer l'ID du groupe de modèles à partir de l'URL
    const getGroupIdFromQuery = () => {
        const params = new URLSearchParams(location.search);
        return params.get('groupId');
    };

    // Fonction pour charger les modèles depuis l'API
    const loadModels = async () => {
        try {
            const groupId = getGroupIdFromQuery();
            const response = await fetchModels(groupId);
            if (Array.isArray(response)) {
                setModels(response); // Met à jour l'état des modèles avec la réponse de l'API
            } else {
                console.error('API returned non-array:', response);
            }
        } catch (error) {
            console.error('Error loading models:', error);
        }
    };

    // Charger les modèles au chargement initial
    useEffect(() => {
        loadModels();
    }, []); // Dépendance vide pour charger une seule fois au montage

    // Fonction pour ajouter une ligne de sous-division
    const handleAddRow = () => {
        setRows([...rows, { Nom: '', Icone: '', Model3d: '' }]);
    };

    // Fonction pour gérer les changements dans les inputs
    const handleInputChange = (index, key, value) => {
        const newRows = [...rows];
        newRows[index][key] = value;
        setRows(newRows);
    };

    // Fonction pour gérer le téléchargement de fichier
    const handleFileUpload = (index, event) => {
        const file = event.target.files[0];
        handleInputChange(index, 'Icone', file ? file.name : '');
    };

    // Fonction pour gérer le téléchargement de fichier Model3d
    const handleModel3dUpload = (index, event) => {
        const file = event.target.files[0];
        handleInputChange(index, 'Model3d', file ? file.name : '');
    };

    // Fonction pour sélectionner les tailles impactées
    const handleSizeSelect = (index) => {
        setCurrentRowIndex(index);
        setTempSizes(rows[index].tailleImpactee);
        setShowModal(true);
    };

    // Fonction pour enregistrer les tailles sélectionnées depuis le modal
    const handleModalSave = () => {
        const newRows = [...rows];
        newRows[currentRowIndex].tailleImpactee = [...tempSizes];
        setRows(newRows);
        setTempSizes([]);
        setShowModal(false);
    };

    // Fonction pour fermer le modal sans enregistrer
    const handleModalClose = () => {
        setTempSizes([]);
        setShowModal(false);
    };

    // Fonction pour gérer le changement de checkbox pour les tailles
    const handleCheckboxChange = (size) => {
        setTempSizes((prev) =>
            prev.includes(size) ? prev.filter((s) => s !== size) : [...prev, size]
        );
    };

    // Fonction pour gérer la validation et la création des modèles
    const handleValidation = async () => {
        const newModels = [];

        for (let i = 0; i < rows.length; i++) {
            const { Nom, Icone, Model3d } = rows[i];

            const newModel = {
                Nom,
                Icone,
                Model3d,
                // Ajouter d'autres propriétés au besoin
            };
            newModels.push(newModel);

            try {
                // Appel à l'API pour créer le modèle
                const response = await createNuancier(newModel);
                console.log('Model created:', response.data);
            } catch (error) {
                console.error('Error creating model:', error);
            }
        }

        setCreatedModels(newModels);

        // Rediriger vers la page options après la validation
        const groupId = getGroupIdFromQuery();
        navigate(`/options?groupId=${groupId}`);
    };

    return (
        <div className="App container">
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>Nom</th>
                    <th>Icone</th>
                    <th>Modèle 3D</th>
                </tr>
                </thead>
                <tbody>
                {rows.map((row, index) => (
                    <tr key={index}>
                        <td>
                            <Form.Control
                                type="text"
                                value={row.Nom}
                                onChange={(e) => handleInputChange(index, 'Nom', e.target.value)}
                            />
                        </td>
                        <td>
                            <Form.Control
                                type="file"
                                label={row.Icone || 'Choisir un fichier'}
                                custom="true"
                                onChange={(e) => handleFileUpload(index, e)}
                            />
                        </td>
                        <td>
                            <Form.Control
                                type="file"
                                label={row.Model3d || 'Choisir un fichier 3D'}
                                custom="true"
                                onChange={(e) => handleModel3dUpload(index, e)}
                            />
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <Button onClick={handleAddRow}>Ajouter une ligne</Button>
            <Button variant="success" className="ml-2" onClick={handleValidation}>
                Valider
            </Button>
            <Button variant="secondary" className="ml-2">
                Annuler
            </Button>

            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Choisissez les tailles impactées</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {models.map((model) => (
                        <Form.Check
                            key={model.id}
                            type="checkbox"
                            label={model.specification}
                            checked={tempSizes.includes(model.specification)}
                            onChange={() => handleCheckboxChange(model.specification)}
                        />
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleModalSave}>
                        Enregistrer
                    </Button>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Affichage des modèles créés */}
            {createdModels.length > 0 && (
                <div>
                    <h2>Modèles créés :</h2>
                    <ul>
                        {createdModels.map((model, index) => (
                            <li key={index}>
                                <strong>Sous Nom:</strong> {model.Nom}, <strong>Spécification:</strong> {model.specification}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default SubDiv;