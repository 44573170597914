import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Button, Card, Row, Col } from 'react-bootstrap';
import {fetchCurrentUser, fetchModels} from '../api';
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from '../navbar.js';

const Form = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showExplanation, setShowExplanation] = useState(false);
    const [models, setModels] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const getUser = async () => {
            try {
                const response = await fetchCurrentUser();
                setUser(response.data);
                console.log(response.data);
            } catch (err) {
                console.error('Error fetching user:', err);
                setError('Error fetching user');
            } finally {
                setLoading(false);
            }
        };

        getUser();
    }, []);

    const logout = () => {
        localStorage.removeItem('token');
        window.location.reload();
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const modelsResponse = await fetchModels();
                const groupId = getGroupIdFromQuery();

                if (groupId) {
                    const parsedGroupId = parseInt(groupId, 10);

                    if (!isNaN(parsedGroupId)) {
                        const filteredModels = modelsResponse.data.$values.filter(model => model.idGroupeModel === parsedGroupId);
                        console.log(filteredModels);
                        setModels(filteredModels);
                    } else {
                        console.error('Invalid groupId:', groupId);
                    }
                } else {
                    console.error('groupId parameter is missing in the URL');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [location]);


    const getGroupIdFromQuery = () => {
        const params = new URLSearchParams(location.search);
        return params.get('groupId');
    };

    const handleExplanationToggle = () => {
        setShowExplanation(!showExplanation);
    };

    const handleYesClick = () => {
        const groupId = getGroupIdFromQuery();
        navigate(`/sousdiv?groupId=${groupId}`);
    };

    const handleNoClick = () => {
        const groupId = getGroupIdFromQuery();
        if (groupId) {
            const filteredModels = models.filter(model => model.idGroupeModel === parseInt(groupId, 10));
            const newModels = filteredModels.map(model => ({
                SousNom: "",
                SousIcone: "",
                IdModel: model.id,
            }));

            localStorage.setItem('createdOptions', JSON.stringify(newModels));
            navigate(`/options?groupId=${groupId}`);
        } else {
            console.error('Group ID not found in the URL');
        }
    };

    return (
        <Layout user={user} logout={logout}>
        <Container
            style={{
                padding: '20px',
                fontFamily: 'Arial, sans-serif',
                backgroundColor: '#ffffff',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
                textAlign: 'center'
            }}
        >
            <style>
                {`
                    .custom-title {
                        color: #94a4e7;
                    }
                    .custom-button-yes {
                        background-color: #2ce3a4;
                        border-color: transparent !important;
                        color: #ffffff;
                        width: 100px;
                        height: 40px;
                        margin: 5px;
                    }
                    .custom-button-no {
                        background-color: #fb2c2c;
                        border-color: transparent !important;
                        color: #ffffff;
                        width: 100px;
                        height: 40px;
                        margin: 5px;
                    }
                    .explanation-text {
                        color: #6b83e3;
                        cursor: pointer;
                        text-decoration: underline;
                    }
                    .explanation-card {
                        background-color: #e0e7ff;
                    }
                `}
            </style>
            <h1 className="mb-4 custom-title">Votre modèle a-t-il des subdivisions ?</h1>
            <Row className="mb-4" style={{ justifyContent: 'center' }}>
                <Col xs="auto">
                    <Button
                        variant="primary"
                        onClick={handleYesClick}
                        className="custom-button-yes"
                    >
                        Oui
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={handleNoClick}
                        className="custom-button-no"
                    >
                        Non
                    </Button>
                </Col>
            </Row>
            <Row style={{ justifyContent: 'center' }}>
                <Col xs="auto">
                    <p
                        onClick={handleExplanationToggle}
                        className="explanation-text"
                    >
                        Qu'est-ce que des subdivisions ?
                    </p>
                    {showExplanation && (
                        <Card className="mt-3 explanation-card" style={{ maxWidth: '400px', margin: '0 auto' }}>
                            <Card.Body>
                                <Card.Text>
                                    Si vous voulez créer un modèle avec des parties de différentes couleurs, il vous faudra créer des subdivisions pour chaque partie qui sera d’une couleur différente (porte, tiroir, base).
                                </Card.Text>
                                <img
                                    src="https://via.placeholder.com/150"
                                    alt="Exemple de subdivisions"
                                    style={{ display: 'block', marginTop: '10px', maxWidth: '100%' }}
                                />
                            </Card.Body>
                        </Card>
                    )}
                </Col>
            </Row>
        </Container>
        </Layout>
    );
};

export default Form;