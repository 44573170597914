import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import Dashboard from './pages/Dashboard';
import CustomerList from './pages/CustomerList';
import CustomerDetails from './pages/CustomerDetails'; // Importer la page CustomerDetails
import Products from './pages/Products';
import Options from './pages/Options';
import Nuancier from './pages/nuancier';
import SubDivision from './pages/SubDivision';
import Sousdiv from './pages/sousdiv';
import Catalogue from './pages/catalogue';
import PrivateRoute from './components/PrivateRoute';
import { useAuth } from './context/AuthContext';
import EditCustomer from './pages/EditCustomer'; // page pour modifier les customers 
import UserList from  './pages/UserList';
import UserCustomersList from './pages/UserCustomersList'; 
import Proposition from "./pages/Proposition"; // Updated import statement with correct casing

function App() {
    const { isAuthenticated } = useAuth();

    return (
        <Router>
            <Routes>
                <Route path="/login" element={<LoginPage />} />

                {/* Les routes suivantes sont protégées par PrivateRoute */}
                <Route element={<PrivateRoute />}>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/customers" element={<CustomerList />} />
                    <Route path="/customers/:id" element={<CustomerDetails />} /> {/* Nouvelle route pour les détails du client */}
                    <Route path="/products" element={<Products />} />
                    <Route path="/options" element={<Options />} />
                    <Route path="/nuancier" element={<Nuancier />} />
                    <Route path="/subdivision" element={<SubDivision />} />
                    <Route path="/sousdiv" element={<Sousdiv />} />
                    <Route path="/catalogue" element={<Catalogue />} />
                    <Route path="/customers/edit/:id" element={<EditCustomer />} />
                    <Route path="/proposition/:id" element={<Proposition />} />
                    <Route path="/Users" element={<UserList />} />
                    <Route path="/users/:userId/customers" element={<UserCustomersList />} />

                </Route>

                {/* Redirection des routes inconnues vers le login ou le dashboard */}
                <Route path="*" element={<Navigate to={isAuthenticated ? "/dashboard" : "/login"} />} />
            </Routes>
        </Router>
    );
}

export default App;
