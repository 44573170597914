import React from 'react';
import { Form } from 'react-bootstrap';
import FileDropzone from './FileDropzone';

const FileUploadSection = ({ label, file, onDrop }) => (
    <Form.Group className="mb-3">
        <Form.Label className="form-label">{label}</Form.Label>
        <FileDropzone
            label={`Déposez un ${label.toLowerCase()} ici`}
            file={file}
            onDrop={onDrop}
        />
    </Form.Group>
);

export default FileUploadSection;