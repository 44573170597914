import React from 'react';
import { Row, Col, Form, ButtonGroup, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import FileUploadSection from './FileUploadSection';
import FileDropzone from './FileDropzone'; 

const ItemDetails = ({
    specification, catalogPrice, handleSpecificationChange, handleCatalogPriceChange,
    placement, handlePlacementChange, icon, handleIconDrop, model3D, handleModel3DDrop,
    pdf, handlePdfDrop, ficheDeCote, handleFicheDeCoteDrop, handleAddItem
}) => (
<>
<Row className="mb-3">
<Col>
<Form.Group>
<Form.Label className="form-label">Specification</Form.Label>
<Form.Control
type="text"
value={specification}
onChange={handleSpecificationChange}
/>
</Form.Group>
</Col>
<Col>
<Form.Group>
<Form.Label className="form-label">Prix catalogue</Form.Label>
<Form.Control
type="text"
value={catalogPrice}
onChange={handleCatalogPriceChange}
/>
</Form.Group>
</Col>
</Row>

<Form.Group className="mb-3">
<Form.Label className="form-label">Placement</Form.Label>
<ButtonGroup>
<Button
variant={placement === 'Sol' ? 'primary' : 'outline-primary'}
onClick={() => handlePlacementChange('Sol')}
className={placement === 'Sol' ? 'custom-button' : 'custom-button-outline'}
>
Sol
</Button>
<Button
variant={placement === 'Plafond' ? 'primary' : 'outline-primary'}
onClick={() => handlePlacementChange('Plafond')}
className={placement === 'Plafond' ? 'custom-button' : 'custom-button-outline'}
>
Plafond
</Button>
</ButtonGroup>
</Form.Group>

<FileUploadSection label="Icone" file={icon} onDrop={handleIconDrop} />
<FileUploadSection label="Modèle 3D" file={model3D} onDrop={handleModel3DDrop} />
<FileUploadSection label="PDF" file={pdf} onDrop={handlePdfDrop} />

<Form.Group className="mb-3">
<Form.Label className="form-label">
Fiche de cote
<OverlayTrigger
placement="top"
overlay={<Tooltip id="tooltip-top">Téléchargez ici la fiche de cote du produit.</Tooltip>}
>
<span className="ms-2" style={{ cursor: 'pointer', color: '#2ce3a4' }}>?</span>
</OverlayTrigger>
</Form.Label>
<FileDropzone
label="Déposez une fiche de cote ici"
file={ficheDeCote}
onDrop={handleFicheDeCoteDrop}
/>
</Form.Group>

<Button
variant="success"
onClick={handleAddItem}
className="mb-3 d-block mx-auto"
style={{ backgroundColor: '#2ce3a4', borderColor: '#2ce3a4' }}
>
Ajouter Item
</Button>
</>
);

export default ItemDetails;