import React, { useEffect, useState } from 'react';
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, BarElement, PointElement, CategoryScale, LinearScale, ArcElement, Filler } from 'chart.js';
import { Container, Row, Col, Card, Button, InputGroup, FormControl } from 'react-bootstrap';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import Layout from '../navbar.js';
import { fetchCurrentUser } from '../api';
import 'react-big-calendar/lib/css/react-big-calendar.css'; // Importer le style du calendrier

ChartJS.register(Title, Tooltip, Legend, LineElement, BarElement, PointElement, CategoryScale, LinearScale, ArcElement, Filler);

const localizer = momentLocalizer(moment);

const Dashboard = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const getUser = async () => {
            try {
                const response = await fetchCurrentUser();
                setUser(response.data);
            } catch (err) {
                setError('Erreur lors de la récupération des informations de l\'utilisateur');
            } finally {
                setLoading(false);
            }
        };
        getUser();
    }, []);

    // Exemples de données statiques pour différents graphiques
    const lineData = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
        datasets: [{
            label: 'Ventes',
            data: [30, 45, 60, 70, 40, 55, 75],
            fill: true,
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            pointBackgroundColor: 'rgba(75, 192, 192, 1)',
            tension: 0.4,
        }]
    };

    const barData = {
        labels: ['Product 1', 'Product 2', 'Product 3', 'Product 4'],
        datasets: [{
            label: 'Quantité vendue',
            data: [120, 150, 180, 90],
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0']
        }]
    };

    const doughnutData = {
        labels: ['Electronics', 'Furniture', 'Grocery', 'Clothing'],
        datasets: [{
            label: 'Ventes par catégorie',
            data: [250, 150, 100, 75],
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0']
        }]
    };

    // Exemples d'événements pour le calendrier
    const events = [
        {
            title: 'Réunion avec l\'équipe',
            start: new Date(2024, 8, 1, 10, 0), 
            end: new Date(2024, 8, 1, 11, 0),
        },
        {
            title: 'Présentation du produit',
            start: new Date(2024, 8, 3, 14, 0),
            end: new Date(2024, 8, 3, 15, 30),
        },
        {
            title: 'Entretien d\'embauche',
            start: new Date(2024, 8, 7, 9, 0),
            end: new Date(2024, 8, 7, 10, 0),
        }
    ];



    if (error) {
        return <div>{error}</div>;
    }

    return (
        <Layout user={user} logout={() => { localStorage.removeItem('token'); window.location.reload(); }}>
            <Container fluid style={{ padding: '20px' }}>
            <Row className="align-items-center mb-4">
            <Col className="d-flex justify-content-start" style={{ paddingLeft: '6rem' }}>
        <h1 style={{ color: '#6b83e3' }}>Tableau de Bord </h1>
    </Col>
    <Col xs="auto" className="ms-auto d-flex justify-content-end align-items-center">
        <img
            src="https://ogmentis.fr/images/Logo_low.png"
            alt="Ogmentis Logo"
            style={{
                height: 'calc(2.7em + 2.2rem)',
                width: 'auto'
            }}
        />
    </Col>
</Row>


                <Row className="mb-4">
                    <Col md={3}>
                        <Card className="text-center">
                            <Card.Body>
                                <Card.Title>1205</Card.Title>
                                <Card.Text>Ventes</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="text-center">
                            <Card.Body>
                                <Card.Title>840</Card.Title>
                                <Card.Text>Nouveaux utilisateurs</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="text-center">
                            <Card.Body>
                                <Card.Title>75%</Card.Title>
                                <Card.Text>Taux de conversion</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="text-center">
                            <Card.Body>
                                <Card.Title>12,000 €</Card.Title>
                                <Card.Text>Revenu mensuel</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className="mb-4">
                    <Col md={8}>
                        <Card>
                            <Card.Body>
                                <Line data={lineData} options={{ maintainAspectRatio: false }} />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card>
                            <Card.Body>
                                <Doughnut data={doughnutData} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className="mb-4">
                    <Col md={6}>
                        <Card>
                            <Card.Body>
                                <Bar data={barData} options={{ maintainAspectRatio: false }} />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card>
                            <Card.Body>
                                <h5>Calendrier</h5>
                                <Calendar
                                    localizer={localizer}
                                    events={events}
                                    startAccessor="start"
                                    endAccessor="end"
                                    style={{ height: 400 }}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};

export default Dashboard;
