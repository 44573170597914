import React from 'react';
import Dropzone from 'react-dropzone';

const FileDropzone = ({ label, file, onDrop }) => (
    <div className="form-group">
        <label htmlFor={label}>{label} :</label>
        <Dropzone onDrop={onDrop}>
            {({ getRootProps, getInputProps }) => (
                <div className="dropzone" {...getRootProps()}>
                    <input {...getInputProps()} />
                    {file ? (
                        <p>Fichier sélectionné : {file.name}</p>
                    ) : (
                        <p>Déposez un fichier ici, ou cliquez pour sélectionner un fichier</p>
                    )}
                </div>
            )}
        </Dropzone>
    </div>
);

export default FileDropzone;