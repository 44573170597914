import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { fetchCustomerDetails, updateCustomerDetails , fetchCurrentUser} from '../api';
import Layout from '../navbar.js';

const EditCustomer = () => {
    const { id } = useParams(); 
    const navigate = useNavigate();
    const [user, setUser] = useState(null);  // Stocker l'objet user
    const [customer, setCustomer] = useState({
        nom: '',
        prenom: '',
        mail: '',
        mobile: '',
        adress: '',
        city: '',
        postalCode: '',
        commentaire: ''
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const getCustomerDetails = async () => {
            try {
                const response = await fetchCustomerDetails(id);
                const data = response.data;
                data.postalCode = data.postalCode.trim();
                setCustomer(data);
            } catch (err) {
                console.error('Error fetching customer details', err);
                setError('Erreur lors de la récupération des détails du client');
            } finally {
                setLoading(false);
            }
        };

        const getUser = async () => {
            try {
                const response = await fetchCurrentUser();
                setUser(response.data);
            } catch (err) {
                setError('Error fetching user');
            }
        };

         getCustomerDetails();
         getUser();
    }, [id]);

    const logout = () => {
        localStorage.removeItem('token');
        window.location.reload();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCustomer(prevCustomer => ({
            ...prevCustomer,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const customerData = {
            nom: customer.nom,
            prenom: customer.prenom,
            mail: customer.mail,
            mobile: customer.mobile,
            adress: customer.adress,
            city: customer.city,
            postalCode: customer.postalCode.trim(),
            commentaire: customer.commentaire
        };

        try {
            await updateCustomerDetails(id, customerData);
            alert("Client mis à jour avec succès !");
            navigate(`/customers/${id}`);
        } catch (err) {
            console.error('Error updating customer', err);
            alert("Erreur lors de la mise à jour du client.");
        }
    };

    const handleCancel = () => {
        navigate(`/customers/${id}`);
    };

    if (loading) {
        return <div>Chargement en cours...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <Layout user={user} logout={logout}>
            <Container>
                <h1 style={{ color: '#6b83e3' }}>Modifier le Client</h1>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col>
                            <Form.Group controlId="formNom">
                                <Form.Label>Nom</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="nom"
                                    value={customer.nom}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="formPrenom">
                                <Form.Label>Prénom</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="prenom"
                                    value={customer.prenom}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="formMail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="mail"
                                    value={customer.mail}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="formMobile">
                                <Form.Label>Mobile</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="mobile"
                                    value={customer.mobile}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="formAdress">
                                <Form.Label>Adresse</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="adress"
                                    value={customer.adress}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="formCity">
                                <Form.Label>Ville</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="city"
                                    value={customer.city}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="formPostalCode">
                                <Form.Label>Code Postal</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="postalCode"
                                    value={customer.postalCode}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="formCommentaire">
                                <Form.Label>Commentaire</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="commentaire"
                                    value={customer.commentaire}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col>
                            <Button variant="primary" type="submit" className="me-2">
                                Sauvegarder
                            </Button>
                            <Button variant="secondary" onClick={handleCancel}>
                                Annuler les Modifications
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Layout>
    );
};

export default EditCustomer;
