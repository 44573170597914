import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from '../navbar.js';
import {fetchModels, createOption, fetchCurrentUser} from '../api'; // Assurez-vous que le chemin est correct pour votre projet

const SubDiv = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [rows, setRows] = useState([{ sousNom: '', sousIcone: '', tailleImpactee: [] }]);
    const [showModal, setShowModal] = useState(false);
    const [currentRowIndex, setCurrentRowIndex] = useState(null);
    const [tempSizes, setTempSizes] = useState([]);
    const [models, setModels] = useState([]);
    const [createdModels, setCreatedModels] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();

    // Fonction pour récupérer l'ID du groupe de modèles à partir de l'URL
    const getGroupIdFromQuery = () => {
        const params = new URLSearchParams(location.search);
        return params.get('groupId');
    };

    useEffect(() => {
        const getUser = async () => {
            try {
                const response = await fetchCurrentUser();
                setUser(response.data);
                console.log(response.data);
            } catch (err) {
                console.error('Error fetching user:', err);
                setError('Error fetching user');
            } finally {
                setLoading(false);
            }
        };

        getUser();
    }, []);

    const logout = () => {
        localStorage.removeItem('token');
        window.location.reload();
    };

    // UseEffect pour récupérer les modèles associés au groupe
    useEffect(() => {
        const fetchData = async () => {
            try {
                const modelsResponse = await fetchModels();
                const groupId = getGroupIdFromQuery();

                if (groupId) {
                    const parsedGroupId = parseInt(groupId, 10);

                    if (!isNaN(parsedGroupId)) {
                        const filteredModels = modelsResponse.data.$values.filter(model => model.idGroupeModel === parsedGroupId);
                        setModels(filteredModels);
                    } else {
                        console.error('Invalid groupId:', groupId);
                    }
                } else {
                    console.error('groupId parameter is missing in the URL');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [location]);

    // Fonction pour ajouter une ligne de sous-division
    const handleAddRow = () => {
        setRows([...rows, { sousNom: '', sousIcone: '', tailleImpactee: [] }]);
    };

    // Fonction pour gérer les changements dans les inputs
    const handleInputChange = (index, key, value) => {
        const newRows = [...rows];
        newRows[index][key] = value;
        setRows(newRows);
    };

    // Fonction pour gérer le téléchargement de fichier
    const handleFileUpload = (index, event) => {
        const file = event.target.files[0];
        handleInputChange(index, 'sousIcone', file ? file.name : '');
    };

    // Fonction pour sélectionner les tailles impactées
    const handleSizeSelect = (index) => {
        setCurrentRowIndex(index);
        setTempSizes(rows[index].tailleImpactee);
        setShowModal(true);
    };

    // Fonction pour enregistrer les tailles sélectionnées depuis le modal
    const handleModalSave = () => {
        const newRows = [...rows];
        newRows[currentRowIndex].tailleImpactee = [...tempSizes];
        setRows(newRows);
        setTempSizes([]);
        setShowModal(false);
    };

    // Fonction pour fermer le modal sans enregistrer
    const handleModalClose = () => {
        setTempSizes([]);
        setShowModal(false);
    };

    // Fonction pour gérer le changement de checkbox pour les tailles
    const handleCheckboxChange = (size) => {
        setTempSizes((prev) =>
            prev.includes(size) ? prev.filter((s) => s !== size) : [...prev, size]
        );
    };

    // Fonction pour gérer la validation et la création des options
    const handleValidation = async () => {
        try {
            const newModels = [];

            // Iterate through each row in the table
            for (const row of rows) {
                const { sousNom, sousIcone, tailleImpactee } = row;

                // Check each model against the selected specifications
                for (const model of models) {
                    // Determine if the model's specification is selected in tailleImpactee
                    const isSelected = tailleImpactee.includes(model.specification);

                    // Check if a similar model already exists in newModels
                    const existingModel = newModels.find(
                        (m) =>
                            m.IdModel === model.id &&
                            m.SousNom === (isSelected ? sousNom : "") && // Only match if isSelected
                            m.SousIcone === (isSelected ? sousIcone : "") // Only match if isSelected
                    );

                    if (!existingModel) {
                        // If not found, add the newModel to newModels
                        const newModel = {
                            SousNom: isSelected ? sousNom : "",
                            SousIcone: isSelected ? sousIcone : "",
                            IdModel: model.id,
                            specification: model.specification // Assuming you want to store specification as well
                        };
                        newModels.push(newModel);
                    }
                }
            }

            // Store data in localStorage
            localStorage.setItem('createdOptions', JSON.stringify(newModels));

            const groupId = getGroupIdFromQuery();
            navigate(`/options?groupId=${groupId}`);
        } catch (error) {
            console.error('Erreur lors de la création des options:', error);
        }
    };

    return (
        <Layout user={user} logout={logout}>
        <div className="container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
            <style>
                {`
                    .custom-button-add {
                        background-color: #94a4e7;
                        border-color: transparent;
                        color: #ffffff;
                        margin: 10px;
                        width: 200px;
                        height: 50px;
                        display: block;
                    }
                    .custom-button-validate {
                        background-color: #2ce3a4;
                        border-color: transparent;
                        color: #ffffff;
                        margin: 10px;
                        width: 200px;
                        height: 50px;
                        display: block;
                    }
                    .custom-button-cancel {
                        background-color: #fb2c2c;
                        border-color: transparent;
                        color: #ffffff;
                        margin: 10px;
                        width: 200px;
                        height: 50px;
                        display: block;
                    }
                    .custom-modal-title {
                        color: #6b83e3;
                    }
                `}
            </style>
            <Table striped bordered hover className="text-center" style={{ width: '80%', marginBottom: '20px' }}>
                <thead>
                <tr>
                    <th>Sous nom</th>
                    <th>Sous icone</th>
                    <th>Taille impactée</th>
                </tr>
                </thead>
                <tbody>
                {rows.map((row, index) => (
                    <tr key={index}>
                        <td>
                            <Form.Control
                                type="text"
                                value={row.sousNom}
                                onChange={(e) => handleInputChange(index, 'sousNom', e.target.value)}
                            />
                        </td>
                        <td>
                            <Form.Control
                                type="file"
                                label={row.sousIcone || 'Choisir un fichier'}
                                custom="true"
                                onChange={(e) => handleFileUpload(index, e)}
                            />
                        </td>
                        <td>
                            <Button variant="light" onClick={() => handleSizeSelect(index)}>
                                {row.tailleImpactee.join(', ') || 'Sélectionner'}
                            </Button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <Button className="custom-button-add" onClick={handleAddRow}>
                Ajouter une ligne
            </Button>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button className="custom-button-validate" onClick={handleValidation}>
                    Valider
                </Button>
                <Button className="custom-button-cancel" onClick={() => navigate(-1)}>
                    Annuler
                </Button>
            </div>

            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="custom-modal-title">Choisissez les tailles impactées</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {models.map((model) => (
                        <Form.Check
                            key={model.id}
                            type="checkbox"
                            label={model.specification}
                            checked={tempSizes.includes(model.specification)}
                            onChange={() => handleCheckboxChange(model.specification)}
                        />
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleModalSave}>
                        Enregistrer
                    </Button>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>

            {createdModels.length > 0 && (
                <div style={{ marginTop: '20px', textAlign: 'center' }}>
                    <h2>Options créées :</h2>
                    <ul>
                        {createdModels.map((model, index) => (
                            <li key={index}>
                                <strong>Sous Nom:</strong> {model.sousNom}, <strong>Spécification:</strong> {model.specification}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
        </Layout>
    );
};

export default SubDiv;