// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { login as loginService } from '../services/authService';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authError, setAuthError] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    console.log("Token found in localStorage:", token);
    if (token) {
      // Optionally validate token (e.g., check expiration date)
      setIsAuthenticated(true);
    }
  }, []);

  const login = async (mail, password) => {
    try {
      const response = await loginService(mail, password);
      const { token } = response;
      console.log("Token received after login:", token); // Log du token reçu
      localStorage.setItem('token', token);
      setIsAuthenticated(true);
      setAuthError(null);
      return response;
    } catch (error) {
      console.error("Login error:", error);
      setIsAuthenticated(false);
      setAuthError(error.message);
      throw error;
    }
  };
  
  const logout = () => {
  setIsAuthenticated(false);
  localStorage.removeItem('token'); // Supprime le token du localStorage
};

  return (
      <AuthContext.Provider value={{ isAuthenticated, login, logout, authError }}>
        {children}
      </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
