import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { BiEnvelope, BiCalendarWeek } from 'react-icons/bi';
import 'bootstrap/dist/css/bootstrap.min.css';
import { fetchUsers, fetchCurrentUser } from '../api';
import Layout from '../navbar.js';
import { Circles } from 'react-loader-spinner'; 

const getRoleColor = (nomRole) => {
    switch(nomRole) {
        case 'Admin':
            return '#28a745'; 
        case 'Vendeur':
            return '#800080'; 
        case 'Manager':
            return '#007bff'; 
        case 'Directeur':
            return '#ffc107'; 
        default:
            return '#ff5733'; 
    }
};

const UserList = () => {
    const [user, setUser] = useState(null);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const getUsers = async () => {
            try {
                const response = await fetchUsers(); 
                setUsers(response.data.$values || []);
            } catch (err) {
                setError('Error fetching users');
            } finally {
                setLoading(false);
            }
        };

        const getCurrentUser = async () => {
            try {
                const response = await fetchCurrentUser();
                setUser(response.data);
            } catch (err) {
                setError('Error fetching current user');
            }
        };

        getUsers(); 
        getCurrentUser();
    }, []);

    const logout = () => {
        localStorage.removeItem('token');
        window.location.reload();
    };

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Circles
                    height="80"
                    width="80"
                    color="#6b83e3"
                    ariaLabel="circles-loading"
                    visible={true}
                />
            </div>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const handleUserClick = (userId) => {
        navigate(`/users/${userId}/customers`); // Redirection vers la page des clients de l'utilisateur
    };
    
    

    return (
        <Layout user={user} logout={logout}>
            <div style={{ marginLeft: '50px', paddingRight: '50px' }}>
                <Row className="align-items-center mb-4">
                    <Col className="d-flex justify-content-start" style={{ paddingLeft: '6rem' }}>
                        <h1 style={{ color: '#6b83e3' }}>Liste des Utilisateurs</h1>
                    </Col>
                    <Col xs="auto" className="ms-auto d-flex justify-content-end align-items-center">
                        <img
                            src="https://ogmentis.fr/images/Logo_low.png"
                            alt="Ogmentis Logo"
                            style={{
                                height: 'calc(2.7em + 2.2rem)',
                                width: 'auto'
                            }}
                        />
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col md={2}>
                        <div className="d-flex align-items-center justify-content-center">
                            <span style={{ color: '#6b83e3' }}>Nom ▼</span>
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className="d-flex align-items-center justify-content-center">
                            <span style={{ color: '#6b83e3' }}>Email ▼</span>
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className="d-flex align-items-center justify-content-center">
                            <span style={{ color: '#6b83e3' }}>Date de création ▼</span>
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className="d-flex align-items-center justify-content-center">
                            <span style={{ color: '#6b83e3' }}>État ▼</span>
                        </div>
                    </Col>
                    <Col md={2} className="d-flex justify-content-center align-items-center">
                        <span style={{ color: '#6b83e3' }}>Entreprise ▼</span>
                    </Col>
                </Row>

                {users.map((item, index) => (
                    <Row key={index} className="my-3">
                        <Col>
                            <Card 
                                className="border-0 shadow rounded mb-3" 
                                style={{ backgroundColor: '#fbf3f3', cursor: 'pointer' }}
                                onClick={() => handleUserClick(item.userId)}  // Lorsqu'on clique, cela redirige vers les clients de l'utilisateur
                            >
                                <Card.Body>
                                    <Row className="align-items-center">
                                        <Col md={2} className="text-center">
                                            <div style={{ color: '#6b83e3' }}>{item.prenom} {item.nom}</div>
                                        </Col>
                                        <Col md={2} className="text-center">
                                            <div style={{ color: '#6b83e3' }}>
                                                <BiEnvelope style={{ color: '#6b83e3' }} /> {item.mail}
                                            </div>
                                        </Col>
                                        <Col md={2} className="text-center">
                                            <div style={{ color: '#6b83e3' }}>
                                                <BiCalendarWeek style={{ color: '#6b83e3' }} /> {item.createdAt}
                                            </div>
                                        </Col>
                                        <Col md={2} className="text-center">
                                            <Button 
                                                variant="success" 
                                                style={{
                                                    backgroundColor: getRoleColor(item.nomRole), 
                                                    borderColor: getRoleColor(item.nomRole)
                                                }}
                                            >
                                                {item.nomRole}
                                            </Button>
                                        </Col>
                                        <Col md={2} className="text-center">
                                            <div style={{ color: '#6b83e3' }}>{item.nomEntreprise}</div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                ))}
            </div>
        </Layout>
    );
};

export default UserList;
