import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Layout from '../navbar.js';
import { fetchCategory, createGroupModel, createItem, uploadFile, fetchCurrentUser, createCategory } from '../api';
import FileDropzone from '../components/FileDropzone.js';
import ModelDetails from '../components/ModelDetails.js';
import ItemDetails from '../components/ItemDetails.js';
import ItemList from '../components/ItemList.js';

const App = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [modelName, setModelName] = useState('');
    const [category, setCategory] = useState('');
    const [image, setImage] = useState(null);
    const [specification, setSpecification] = useState('');
    const [catalogPrice, setCatalogPrice] = useState('');
    const [placement, setPlacement] = useState('');
    const [icons, setIcons] = useState([]);
    const [model3D, setModel3D] = useState(null);
    const [pdf, setPdf] = useState(null);
    const [ficheDeCote, setFicheDeCote] = useState(null);
    const [items, setItems] = useState([]);
    const [categories, setCategories] = useState([]);
    const [categoryIds, setCategoryIds] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        const getUser = async () => {
            try {
                const response = await fetchCurrentUser();
                setUser(response.data);
                console.log(response.data);
            } catch (err) {
                console.error('Error fetching user:', err);
                setError('Error fetching user');
            } finally {
                setLoading(false);
            }
        };

        getUser();
    }, []);

    const logout = () => {
        localStorage.removeItem('token');
        window.location.reload();
    };

    useEffect(() => {
        const getCategories = async () => {
            try {
                const response = await fetchCategory();
                if (Array.isArray(response.data.$values)) {
                    const categoriesData = response.data.$values;
                    const categoriesMap = {};
                    categoriesData.forEach(cat => {
                        categoriesMap[cat.nomCategorie] = cat.id;
                    });
                    setCategories(categoriesData.map(cat => cat.nomCategorie));
                    setCategoryIds(categoriesMap);
                } else {
                    console.error('Invalid categories format:', response.data);
                }
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        getCategories();
    }, []);

    const handleModelNameChange = (e) => {
        setModelName(e.target.value);
    };

    const handleCategoryChange = (e) => {
        setCategory(e.target.value);
    };

    const handlePlacementChange = (placementOption) => {
        setPlacement(placementOption);
    };

    const handleSpecificationChange = (e) => {
        setSpecification(e.target.value);
    };

    const handleCatalogPriceChange = (e) => {
        setCatalogPrice(e.target.value);
    };

    const handleImageDrop = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setImage(acceptedFiles[0]);
        }
    };

    const handleIconDrop = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setIcons([...icons, ...acceptedFiles]);
        }
    };

    const handleModel3DDrop = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setModel3D(acceptedFiles[0]);
        }
    };

    const handlePdfDrop = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setPdf(acceptedFiles[0]);
        }
    };

    const handleFicheDeCoteDrop = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setFicheDeCote(acceptedFiles[0]);
        }
    };

    const handleAddItem = () => {
        const newItem = {
            specification,
            catalogPrice,
            icons,
            model3D,
            pdf,
            ficheDeCote,
            placement,
        };
        setItems([...items, newItem]);
        setSpecification('');
        setCatalogPrice('');
        setIcons([]);
        setModel3D(null);
        setPdf(null);
        setFicheDeCote(null);
        setPlacement('');
    };

    const handleRemoveItem = (index) => {
        setItems(items.filter((_, i) => i !== index));
    };

    const handleCancel = () => {
        setModelName('');
        setCategory('');
        setImage(null);
        setSpecification('');
        setCatalogPrice('');
        setIcons([]);
        setModel3D(null);
        setPdf(null);
        setFicheDeCote(null);
        setPlacement('');
        setItems([]);
    };

    const handleCreateCategory = async (newCategoryName) => {
        try {
            const response = await createCategory({ nomCategorie: newCategoryName }); // Updated payload format
            // Optionally, update categories state with the new category
            setCategories([...categories, newCategoryName]);
            setCategoryIds({ ...categoryIds, [newCategoryName]: response.data.id });
            setCategory(newCategoryName); // Automatically select the new category
        } catch (error) {
            console.error('Error creating category:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const categoryId = categoryIds[category] || null;

            // Upload the main image and get the path
            const imagePath = image ? await uploadFile(image, user.nom) : '';

            // Prepare the group model
            const groupModel = {
                idCategorie: categoryId,
                nom: modelName,
                cheminImage: imagePath,
            };

            const groupModelResponse = await createGroupModel(groupModel);
            const groupId = groupModelResponse.data.id;

            // Handle multiple item uploads without additional requests
            await Promise.all(items.map(async (item) => {
                const iconPaths = await Promise.all(item.icons.map(icon => uploadFile(icon, user.nom))); // Upload all icons
                const model3DPath = item.model3D ? await uploadFile(item.model3D, user.nom) : '';
                const pdfPath = item.pdf ? await uploadFile(item.pdf, user.nom) : '';
                const ficheDeCotePath = item.ficheDeCote ? await uploadFile(item.ficheDeCote, user.nom) : '';

                // Create the item with the uploaded paths
                await createItem({
                    idGroupeModel: groupId,
                    specification: item.specification,
                    prixCatalogue: item.catalogPrice,
                    cheminIcone: iconPaths.join(','), // Store icon paths
                    chemin3d: model3DPath,
                    cheminPdf: pdfPath,
                    cheminFicheDeCote: ficheDeCotePath,
                    placement: item.placement,
                });
            }));

            // Reset the form
            setModelName('');
            setCategory('');
            setImage(null);
            setIcons([]);
            setItems([]);

            navigate(`/subdivision?groupId=${groupId}`);
        } catch (error) {
            console.error('Error creating group model and items:', error);
        }
    };

    return (
        <Layout user={user} logout={logout}>
            <Container className="mt-4">
                <Form className="custom-form p-4" onSubmit={handleSubmit}>
                    <Row>
                        <Col className="text-center">
                            <h1 style={{ color: '#6c84e4' }}>Création de produits</h1>
                        </Col>
                    </Row>

                    <ModelDetails
                        modelName={modelName}
                        handleModelNameChange={handleModelNameChange}
                        category={category}
                        categories={categories}
                        handleCategoryChange={handleCategoryChange}
                        createCategory={handleCreateCategory} // Pass the createCategory function
                    />

                    <Form.Group className="mb-3">
                        <Form.Label className="form-label">Image</Form.Label>
                        <FileDropzone
                            label="Déposez une image ici"
                            file={image}
                            onDrop={handleImageDrop}
                        />
                    </Form.Group>

                    <ItemDetails
                        specification={specification}
                        catalogPrice={catalogPrice}
                        handleSpecificationChange={handleSpecificationChange}
                        handleCatalogPriceChange={handleCatalogPriceChange}
                        placement={placement}
                        handlePlacementChange={handlePlacementChange}
                        icons={icons}
                        handleIconDrop={handleIconDrop}
                        model3D={model3D}
                        handleModel3DDrop={handleModel3DDrop}
                        pdf={pdf}
                        handlePdfDrop={handlePdfDrop}
                        ficheDeCote={ficheDeCote}
                        handleFicheDeCoteDrop={handleFicheDeCoteDrop}
                        handleAddItem={handleAddItem}
                    />

                    <div className="button-group mb-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="secondary" onClick={handleCancel} className="me-2" style={{ backgroundColor: '#fb2c2c', color: '#ffffff', borderColor: '#fb2c2c' }}>
                            Annuler
                        </Button>
                        <Button type="submit" variant="primary" style={{ backgroundColor: '#6c84e4' }}>
                            Créer
                        </Button>
                    </div>

                    <ItemList items={items} handleRemoveItem={handleRemoveItem} />
                </Form>
            </Container>
        </Layout>
    );
};

export default App;