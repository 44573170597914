import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import { BiEnvelope, BiCalendarWeek } from 'react-icons/bi';
import 'bootstrap/dist/css/bootstrap.min.css';
import { fetchCustomersForUser } from '../api'; // Importer la méthode qui appelle l'API
import Layout from '../navbar.js';
import { Circles } from 'react-loader-spinner'; 

const UserCustomersList = () => {
  const { userId } = useParams(); // Récupérer l'ID de l'utilisateur sélectionné depuis l'URL
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getCustomers = async () => {
      try {
        const response = await fetchCustomersForUser(userId); // Appel API pour récupérer les clients
        if (response.data && Array.isArray(response.data.$values)) {
          setCustomers(response.data.$values);
        } else {
          setError('Unexpected response format');
        }
      } catch (err) {
        setError('Error fetching customers');
      } finally {
        setLoading(false);
      }
    };

    getCustomers();
  }, [userId]);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Circles height="80" width="80" color="#6b83e3" ariaLabel="circles-loading" visible={true} />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Layout>
      <div style={{ marginLeft: '50px', paddingRight: '50px' }}>
        <Row className="align-items-center mb-4">
          <Col className="d-flex justify-content-start" style={{ paddingLeft: '6rem' }}>
            <h1 style={{ color: '#6b83e3' }}>Liste des Clients</h1>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={3}>
            <div className="d-flex align-items-center justify-content-center">
              <span style={{ color: '#6b83e3' }}>Nom et Prénom ▼</span>
            </div>
          </Col>
          <Col md={4}>
            <div className="d-flex align-items-center justify-content-center">
              <span style={{ color: '#6b83e3' }}>Email ▼</span>
            </div>
          </Col>
          <Col md={3}>
            <div className="d-flex align-items-center justify-content-center">
              <span style={{ color: '#6b83e3' }}>Date de création ▼</span>
            </div>
          </Col>
          <Col md={2}>
            <div className="d-flex align-items-center justify-content-center">
              <span style={{ color: '#6b83e3' }}>Nombre de Propositions ▼</span>
            </div>
          </Col>
        </Row>

        {customers.map((customer) => (
          <Row key={customer.customerId} className="my-3">
            <Col>
              <Card className="border-0 shadow rounded mb-3" style={{ backgroundColor: '#fbf3f3' }}>
                <Card.Body>
                  <Row className="align-items-center">
                    <Col md={3} className="text-center">
                      <div style={{ color: '#6b83e3' }}>{`${customer.nom} ${customer.prenom}`}</div>
                    </Col>
                    <Col md={4} className="text-center">
                      <div style={{ color: '#6b83e3' }}>
                        <BiEnvelope style={{ color: '#6b83e3' }} /> {customer.mail}
                      </div>
                    </Col>
                    <Col md={3} className="text-center">
                      <div style={{ color: '#6b83e3' }}>
                        <BiCalendarWeek style={{ color: '#6b83e3' }} /> {customer.createdAt}
                      </div>
                    </Col>
                    <Col md={2} className="text-center">
                      <div style={{ color: '#6b83e3' }}>{customer.propositionCount || 0}</div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ))}
      </div>
    </Layout>
  );
};

export default UserCustomersList;
