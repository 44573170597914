import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { BiDollarCircle } from 'react-icons/bi';
import 'bootstrap/dist/css/bootstrap.min.css';
import {fetchModels, fetchCurrentUser, fetchGroupModels, editEntreprise, fetchEntreprise} from '../api';
import Layout from '../navbar.js';

const Catalogue = () => {
    const [user, setUser] = useState(null);
    const [entreprise, setEntreprise] = useState(null);
    const [groupModels, setGroupModels] = useState([]);
    const [models, setModels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [expandedCardIndex, setExpandedCardIndex] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const getUser = async () => {
            try {
                const response = await fetchCurrentUser();
                console.log('User data:', response.data);
                setUser(response.data);
            } catch (err) {
                console.error('Error fetching user:', err);
                setError('Error fetching user');
            } finally {
                setLoading(false);
            }
        };
        getUser();
    }, []);

    useEffect(() => {
        const getEntreprise = async () => {
            try {
                const response = await fetchEntreprise(2)
                console.log('Entreprise data:', response.data);
                setEntreprise(response.data);
            } catch (err) {
                console.error('Error fetching user:', err);
                setError('Error fetching user');
            } finally {
                setLoading(false);
            }
        };
        getEntreprise();
    }, []);

    useEffect(() => {
        const getGroupModels = async () => {
            try {
                const response = await fetchGroupModels();
                console.log('GroupModels data:', response.data.$values);
                if (Array.isArray(response.data.$values)) {
                    setGroupModels(response.data.$values);
                } else {
                    console.error('Unexpected response format for GroupModels:', response.data);
                    setError('Unexpected response format for GroupModels');
                }
            } catch (err) {
                console.error('Error fetching GroupModels:', err);
                setError('Error fetching GroupModels');
            } finally {
                setLoading(false);
            }
        };
        getGroupModels();
    }, []);

    useEffect(() => {
        const getModels = async () => {
            try {
                const response = await fetchModels();
                console.log('Models data:', response.data.$values);
                if (Array.isArray(response.data.$values)) {
                    setModels(response.data.$values);
                } else {
                    console.error('Unexpected response format for Models:', response.data);
                    setError('Unexpected response format for Models');
                }
            } catch (err) {
                console.error('Error fetching Models:', err);
                setError('Error fetching Models');
            } finally {
                setLoading(false);
            }
        };
        getModels();
    }, []);

    const logout = () => {
        localStorage.removeItem('token');
        window.location.reload();
    };

    const handleCreateProduct = () => {
        navigate('/products');
    };

    const handleValidateCatalogue = async () => {
        try {
            if (user && user.entrepriseId) { // Assuming user has a companyId property
                const currentDate = new Date();
                const formattedDate = currentDate.toISOString().split('T')[0]; // Formate la date au format yyyy-mm-dd
                const payload = {
                    nomEntreprise: entreprise.nomEntreprise,
                    entrepriseId: user.entrepriseId,
                    dateValidCatalogue: formattedDate // Utilisation de la date formatée
                };
                const response = await editEntreprise(user.entrepriseId, payload);
                console.log('Catalogue validated successfully!', response.data);
            } else {
                console.error('User company ID is not available');
                setError('User company ID is not available');
            }
        } catch (err) {
            console.error('Error validating catalogue:', err);
            setError('Error validating catalogue');
        }
    };

    const handleCardClick = (index) => {
        setExpandedCardIndex(expandedCardIndex === index ? null : index);
    };

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <Layout user={user} logout={logout}>
              {/* Barre blanche verticale pour créer un espace après la navbar */}
      <div style={{ backgroundColor: 'white', width: '20px', height: '100vh', position: 'fixed', top: 0, left: '16.66%', zIndex: 999,  }}></div>
      <div style={{ paddingRight: '20px' }}> {/* Ajout d'un padding à droite */}
    <Row className="align-items-center mb-4">
        <Col className="d-flex justify-content-start" style={{ paddingLeft: '6rem' }}>
            <h1 style={{ color: '#6b83e3' }}>Catalogue</h1>
        </Col>
        <Col xs={12} md={4} className="d-flex justify-content-end align-items-center">
            <div style={{ textAlign: 'right' }}>
                <img
                    src="https://ogmentis.fr/images/Logo_low.png"
                    alt="Ogmentis Logo"
                    style={{
                        height: 'calc(2.7em + 2.2rem)',
                        width: 'auto'
                    }}
                />
                <div className="mt-3">
                    <Button
                        variant="primary"
                        style={{ backgroundColor: '#6b83e3', borderColor: '#6b83e3', marginRight: '10px' }}
                        onClick={handleCreateProduct}
                    >
                        Créer un produit
                    </Button>
                    <Button
                        variant="success"
                        style={{ backgroundColor: '#28a745', borderColor: '#28a745' }}
                        onClick={handleValidateCatalogue}
                    >
                        Valider le catalogue
                    </Button>
                </div>
            </div>
        </Col>
    </Row>
</div>



            {/* Ligne de filtrage */}
            <Row className="mb-3">
                <Col md={4}>
                    <div className="d-flex align-items-center justify-content-center">
                        <span style={{ color: '#6b83e3' }}>N° Produit ▼</span>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="d-flex align-items-center justify-content-center">
                        <span style={{ color: '#6b83e3' }}>Nom ▼</span>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="d-flex align-items-center justify-content-center">
                        <span style={{ color: '#6b83e3' }}>Prix ▼</span>
                    </div>
                </Col>
            </Row>

            {/* Cartes des articles */}
            {groupModels.map((groupModel, index) => (
                <Row key={index} className="my-3">
                    <Col>
                        <Card
                            className="border-0 shadow rounded mb-3"
                            style={{ backgroundColor: '#fbf3f3', cursor: 'pointer' }}
                            onClick={() => handleCardClick(index)}
                        >
                            <Card.Body>
                                <Row className="align-items-center">
                                    <Col md={4} className="text-center">
                                        <div style={{ color: '#6b83e3' }}># {groupModel.id}</div>
                                    </Col>
                                    <Col md={4} className="text-center">
                                        <div style={{ color: '#6b83e3' }}>{groupModel.nom}</div>
                                    </Col>
                                    <Col md={4} className="text-center">
                                        <div style={{ color: '#6b83e3' }}><BiDollarCircle style={{ color: '#6b83e3' }} /> ${groupModel.price}</div>
                                    </Col>
                                </Row>
                                {expandedCardIndex === index && (
                                    <Row className="mt-3">
                                        <Col>
                                            <h5 style={{ color: '#6b83e3' }}>Models:</h5>
                                            {models.filter(model => model.idGroupeModel === groupModel.id).length > 0 ? (
                                                models.filter(model => model.idGroupeModel === groupModel.id).map((model, modelIndex) => (
                                                    <div key={modelIndex} style={{ marginBottom: '10px' }}>
                                                        <strong>Model ID:</strong> {model.id}, <strong>Name:</strong> {model.specification}, <strong>Price:</strong> ${model.prixCatalogue}
                                                    </div>
                                                ))
                                            ) : (
                                                <div>No models available</div>
                                            )}
                                        </Col>
                                    </Row>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            ))}
        </Layout>
    );
};

export default Catalogue;