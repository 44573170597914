import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Alert, Card, ListGroup } from 'react-bootstrap'; // Ajout de l'import Alert ici
import 'bootstrap/dist/css/bootstrap.min.css';
import { fetchProposition, fetchCurrentUser, generateSecureLink } from '../api';
import Layout from '../navbar.js';
import { useParams, useNavigate } from 'react-router-dom'; // Ajout de useNavigate pour le bouton "Précédent"

const Proposition = () => {
    const [proposition, setProposition] = useState(null);
    const [user, setUser] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);  // Pour les erreurs générales
    const [downloadError, setDownloadError] = useState(null); // Pour les erreurs de téléchargement
    const [videoPath, setVideoPath] = useState('');
    const [pdfUrl, setPdfUrl] = useState(''); // State pour l'URL du PDF
    const { id } = useParams(); 
    const navigate = useNavigate(); // Pour rediriger l'utilisateur

    useEffect(() => {
        const getProposition = async () => {
            try {
                const response = await fetchProposition(id);
                setProposition(response.data);

                if (response.data && response.data.cheminVideo) {
                    setVideoPath(response.data.cheminVideo);
                } else {
                    console.error('No video path found for the proposition');
                }

                const apiUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5166';
                setPdfUrl(`${apiUrl}/api/Uploader/downloadPDF/${id}`); // Utilise l'ID de la proposition pour le lien PDF
            } catch (err) {
                console.error('Error fetching proposition:', err);
                setError('Erreur lors de la récupération de la proposition');
            } finally {
                setLoading(false);
            }
        };

        getProposition();
    }, []);

    useEffect(() => {
        const getUser = async () => {
            try {
                const response = await fetchCurrentUser();
                setUser(response.data);
            } catch (err) {
                console.error('Error fetching user:', err);
                setError('Erreur lors de la récupération de l\'utilisateur');
            } finally {
                setLoading(false);
            }
        };

        getUser();
    }, []);

    // Fonction pour télécharger le PDF
    const handleDownloadPDF = () => {
        const token = localStorage.getItem('token'); 
    
        if (pdfUrl) {
            fetch(pdfUrl, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`, // Ajouter le token à la requête
                },
            })
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 404) {
                        setDownloadError('Aucun document associé à cette proposition.');
                    } else {
                        setDownloadError('Erreur lors du téléchargement du document.');
                    }
                    throw new Error('Erreur de téléchargement');
                }
                return response.blob(); // Recevoir le fichier sous forme de blob
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Devis_${id}.pdf`); 
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                setDownloadError(null);
            })
            .catch((err) => console.error('Erreur:', err));
        } else {
            console.error('No PDF URL available');
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        window.location.reload();
    };

    const handleWatchVideo = () => {
        if (videoPath) {
            const apiUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5166';
            const url = `${apiUrl}/api/Video/watch/${videoPath}`;
            
            window.open(url, '_blank');
        } else {
            console.error('No video path available to watch');
        }
    };

    const handleCopyLink = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5166';
            const response = await generateSecureLink(proposition.propositionId, videoPath);
            const secureLink = `${apiUrl}${response.data.link}`;
            await navigator.clipboard.writeText(secureLink);
            alert('Lien copié dans le presse-papier!');
        } catch (err) {
            console.error('Failed to generate or copy the link: ', err);
        }
    };

    if (error) {
        return <div>{error}</div>;
    }
    

    return (
        <Layout user={user} logout={logout}>
            <Row className="align-items-center mb-4">
                <Col>
                    {/* Bouton "Précédent" pour retourner à la liste des propositions */}
                    <Button variant="link" onClick={() => navigate(-1)} style={{ color: '#6b83e3', fontSize: '18px' }}>
                        &#8592; Précédent
                    </Button>
                    <h1 style={{ color: '#6b83e3' }}>Proposition</h1>
                </Col>
                <Col className="d-flex justify-content-end align-items-center">
                    <img
                        src="https://ogmentis.fr/images/Logo_low.png"
                        alt="Ogmentis Logo"
                        style={{
                            height: 'calc(2.7em + 2.2rem)',
                            width: 'auto'
                        }}
                    />
                </Col>
            </Row>

            {proposition && (
                <>
                    <Card className="border-0 shadow rounded mb-3" style={{ backgroundColor: '#fbf3f3' }}>
                        <Card.Body>
                            <ListGroup variant="flush">
                                <ListGroup.Item><strong>Description:</strong> {proposition.description || 'N/A'}</ListGroup.Item>
                                <ListGroup.Item><strong>Date de Création:</strong> {proposition.dateCreation || 'N/A'}</ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Card>

                    <h2 style={{ color: '#6b83e3' }}>Document PDF</h2>
                    <Button variant="info" onClick={handleDownloadPDF}>
                        Télécharger le Devis
                    </Button>

                    {/* Affichage du bouton pour visionner la vidéo */}
                    {videoPath && (
                        <div className="mt-4">
                            <Button
                                variant="success"
                                style={{ backgroundColor: '#2ce3a4', borderColor: '#2ce3a4', marginRight: '10px' }}
                                onClick={handleWatchVideo}
                            >
                                Visionner
                            </Button>
                            <Button
                                variant="primary"
                                style={{ backgroundColor: '#007bff', borderColor: '#007bff' }}
                                onClick={handleCopyLink}
                            >
                                Copier le lien
                            </Button>
                        </div>
                    )}

                    {/* Affichage du message d'erreur pour le téléchargement du PDF */}
                    {downloadError && (
                        <Row className="my-3">
                            <Col>
                                <Alert variant="danger">{downloadError}</Alert>
                            </Col>
                        </Row>
                    )}
                </>
            )}
        </Layout>
    );
};

export default Proposition;
