import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, ListGroup, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { fetchCustomerDetails, deleteCustomer, fetchCurrentUser } from '../api';
import Layout from '../navbar.js';
import { BiEnvelope, BiCalendarWeek } from 'react-icons/bi';

const CustomerDetails = () => {
    const [user, setUser] = useState(null); // State pour l'utilisateur
    const { id } = useParams(); 
    const navigate = useNavigate();
    const [customer, setCustomer] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const getCustomerDetails = async () => {
            try {
                const response = await fetchCustomerDetails(id);
                setCustomer(response.data);
            } catch (err) {
                console.error('Error fetching customer details', err);
                setError('Erreur lors de la récupération des détails du client');
            } finally {
                setLoading(false);
            }
        }; 

        const getUser = async () => {
            try {
                const response = await fetchCurrentUser();
                setUser(response.data);
            } catch (err) {
                setError('Erreur lors de la récupération des informations de l\'utilisateur');
            }
        };

        getUser();
        getCustomerDetails();
    }, [id]);

    const logout = () => {
        localStorage.removeItem('token');
        window.location.reload();
    };

    const handleDelete = async () => {
        const confirmDelete = window.confirm("Voulez-vous vraiment supprimer ce client ? NB: cela supprimera toutes les propositions ainsi les vidéos associés ");
        if (confirmDelete) {
            try {
                await deleteCustomer(id);
                alert("Client supprimé avec succès !");
                navigate("/customers");
            } catch (err) {
                alert("Erreur lors de la suppression du client.");
            }
        }
    };

    const handleEdit = () => {
        navigate(`/customers/edit/${id}`);
    };

    const handleBack = () => {
        navigate("/customers");
    };

    if (loading) {
        return <div>Chargement en cours...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!customer) {
        return <div>Aucun client trouvé</div>;
    }

    return (
        <Layout user={user} logout={logout}>
            <Container>
                <Row className="align-items-center mb-4">
                    <Col>
                        <div className="d-flex align-items-center">
                            <span 
                                onClick={handleBack} 
                                style={{ cursor: 'pointer', marginRight: '40px', fontSize: '50px', color: '#6b83e3' }}
                            >
                                &#8592; {/* Flèche gauche unicode */}
                            </span>
                            <h1 style={{ color: '#6b83e3' }}>Détails du Client</h1>
                        </div>
                    </Col>
                    <Col className="text-end">
                        <Button variant="secondary" onClick={handleEdit} className="me-2">
                            Modifier le client
                        </Button>
                        <Button variant="danger" onClick={handleDelete}>
                            Supprimer le client
                        </Button>
                    </Col>
                </Row>
                <Card className="border-0 shadow rounded mb-3" style={{ backgroundColor: '#fbf3f3' }}>
                    <Card.Body>
                        <ListGroup variant="flush">
                            <ListGroup.Item><strong>Nom:</strong> {customer.nom || 'N/A'}</ListGroup.Item>
                            <ListGroup.Item><strong>Prénom:</strong> {customer.prenom || 'N/A'}</ListGroup.Item>
                            <ListGroup.Item><strong>Email:</strong> {customer.mail || 'N/A'}</ListGroup.Item>
                            <ListGroup.Item><strong>Mobile:</strong> {customer.mobile || 'N/A'}</ListGroup.Item>
                            <ListGroup.Item><strong>Adresse:</strong> {customer.adress || 'N/A'}</ListGroup.Item>
                            <ListGroup.Item><strong>Ville:</strong> {customer.city || 'N/A'}</ListGroup.Item>
                            <ListGroup.Item><strong>Code Postal:</strong> {customer.postalCode || 'N/A'}</ListGroup.Item>
                            <ListGroup.Item><strong>Commentaire:</strong> {customer.commentaire || 'N/A'}</ListGroup.Item>
                            <ListGroup.Item><strong>Date de Création:</strong> {customer.createdAt || 'N/A'}</ListGroup.Item>
                        </ListGroup>
                    </Card.Body>
                </Card>

                <h2 style={{ color: '#6b83e3' }}>Propositions</h2>

                {/* En-têtes des colonnes pour les propositions */}
                <Row className="mb-3">
                    <Col md={3}>
                        <div className="d-flex align-items-center justify-content-center">
                            <span style={{ color: '#6b83e3' }}>Status ▼</span>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="d-flex align-items-center justify-content-center">
                            <span style={{ color: '#6b83e3' }}>Description ▼</span>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className="d-flex align-items-center justify-content-center">
                            <span style={{ color: '#6b83e3' }}>Date de Création ▼</span>
                        </div>
                    </Col>
                </Row>

                {customer.propositions && customer.propositions.$values && customer.propositions.$values.length > 0 ? (
                    customer.propositions.$values.map((proposition, index) => (
                        <Row key={index} className="my-3">
                            <Col>
                                <Card 
                                    className="border-0 shadow rounded mb-3 customer-card" 
                                    style={{ backgroundColor: '#fbf3f3', cursor: 'pointer' }} 
                                    onClick={() => navigate(`/proposition/${proposition.propositionId}`)}
                                >
                                    <Card.Body>
                                        <Row className="align-items-center">
                                            <Col md={3} className="text-center">
                                            <Button 
    variant={proposition.statusName === "Voir la vidéo" ? "success" : 
             proposition.statusName === "Vidéo vue" ? "primary" : 
             "warning"} 
    style={{ 
        backgroundColor: proposition.statusName === "Voir la vidéo" ? '#2ce3a4' : 
                         proposition.statusName === "Vidéo vue" ? '#007bff' : 
                         '#f0ad4e', 
        borderColor: proposition.statusName === "Voir la vidéo" ? '#2ce3a4' : 
                     proposition.statusName === "Vidéo vue" ? '#007bff' : 
                     '#f0ad4e' 
    }}
>
    {proposition.statusName === "Voir la vidéo" ? "Voir la vidéo" : 
     proposition.statusName === "Vidéo vue" ? "Vidéo vue" : 
     "Aucune vidéo"}
</Button>

                                            </Col>
                                            <Col md={4} className="text-center">
                                                <div style={{ color: '#6b83e3' }}>{proposition.description || 'N/A'}</div>
                                            </Col>
                                            <Col md={3} className="text-center">
                                                <div style={{ color: '#6b83e3' }}>
                                                    <BiCalendarWeek style={{ color: '#6b83e3' }} /> {proposition.dateCreation || 'N/A'}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    ))
                ) : (
                    <div>Aucune proposition disponible pour ce client.</div>
                )}
            </Container>
        </Layout>
    );
};

export default CustomerDetails;
